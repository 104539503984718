import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import { Wrapper } from "../components/Wrapper"
import Button from "../components/Button"
import Cta from "../components/Cta/Index"
import { Row } from "../components/Layout/Grid"
import {CustomWrapper} from "../components/Wrapper"

import Data from "../components/Projects/data"
import Banner from "../assets/images/gss-banner.png"

const Div = styled.div`
  position: relative;
  padding: 100px 0 0;
`

const Inner = styled.div`
  width: 70%;
`

const Title = styled.p`
  font-size: 28px;
  line-height: 1.2;
  margin: 0;
`

const Desc = styled.p`
  font-size: 16px;
  margin-top: 30px;
  line-height: 1.5;
  color: #808080;
`

const Details = styled.ul`
  display: flex;
  justify-content: space-around;
  padding: 0;
  list-style: none;
  margin-top: 60px;
`

const Detail = styled.li`
  width: calc(100% / 4);
`

const SubTitle = styled.p`
  text-transform: uppercase;
  font-size: 16px;
  color: #404040;
`

const DetailData = styled.p`
  font-size: 14px;
  color: #808080;
  margin-top: 10px;
`
const ImageWrapper = styled.div`
  position: relative;
  margin-top: 100px;
  background-color: #f1f1f1;
  padding: 100px 70px;
`

const Img = styled.img`
  max-width: 100%;
  width: 60%;
  display: block;
  margin: 0 auto;
  &:nth-of-type(n + 2) {
    margin-top: 60px;
  }
`

const BannerText = styled.p`
    margin-bottom: 40px;
    color: #fff;
`

const Work = () => (
  <Layout>
    <Wrapper>
      <Div>
        <Inner>
          <Title>Escape – Saxon Cambell</Title>
          <Desc>
            It is a paradisematic country, in which roasted parts of sentences
            fly into your mouth. Even the all-powerful Pointing has no control
            about the blind texts it is an almost unorthographic life One day
            however a small line of blind text by the name of Lorem Ipsum
            decided to leave for the far World of Grammar.
          </Desc>
        </Inner>
        <Details>
          <Detail>
            <SubTitle>CLIENT</SubTitle>
            <DetailData>Saxon Campbell</DetailData>
          </Detail>
          <Detail>
            <SubTitle>Project Date</SubTitle>
            <DetailData>2016-17</DetailData>
          </Detail>
          <Detail>
            <SubTitle>CATEGORY</SubTitle>
            <DetailData>Branding · Web</DetailData>
          </Detail>
          <Detail>
            <Button primary>VIEW PROJECT</Button>
          </Detail>
        </Details>
      </Div>
    </Wrapper>
    <ImageWrapper>
      {Data.map(image => (
        <Img src={image.img} alt="" />
      ))}
    </ImageWrapper>
    <CustomWrapper margin={"100px auto"}>
      <Cta path={Banner}>
        <Row vcenter hlign={"center"} height={"100%"} direction={"column"}>
          <BannerText>
            Eam te iisque persequeris, agam expetenda erroribus ei sea. Mel vero
            etiam audiam in, dicta
            <br />
            quodsi vim no, ex sed numquam voluptua.
          </BannerText>
          <Button primary>
              Contact Us
          </Button>
        </Row>
      </Cta>
    </CustomWrapper>
  </Layout>
)

export default Work
